export const baseUrl = 'https://universalportal-sso.qacitizensbank.com';
export const assetPrefix = "/footer-mfe/";
export const assetURL = `${baseUrl}${assetPrefix}`;

export const environment = {
  production: false,
  isQA: true,
  isQA2: false,
  isSIT: false,
  isDEV: false,

  footer: {
    1: { type: 'link', text: 'Security', href: 'https://citizensbank-commercial--commops.sandbox.my.site.com/CommercialDigitalGateway/s/article/Security' },
    2: { type: 'link', text: 'Privacy Rights', href: 'https://www.citizensbank.com/account-safeguards/privacy.aspx' },
    3: { type: 'link', text: 'Terms of Use', href: 'https://citizensbank-commercial--commops.sandbox.my.site.com/CommercialDigitalGateway/s/article/Terms-of-Use' },
    4: { type: 'link', text: 'FDIC Coverage', href: 'https://www.fdic.gov/' },
    5: { type: 'link', text: 'Site Map', href: 'https://citizensbank-commercial--sit.sandbox.my.site.com/CommercialDigitalGateway/s/sitemap' },
    6: { type: 'link', text: 'Citizens Commercial', href: 'https://www.citizensbank.com/corporate-finance/overview.aspx' }
  },
  footerCopyright: 'Citizens Financial Group, Inc. All rights reserved. Citizens Commercial Banking is a brand name of Citizens Bank, N.A. Member FDIC.',
  footerDisclaimer: 'Disclaimer: Cash Flow Forecasting provides information for informational purposes only, subject to our terms and conditions. We do not provide financial, accounting, tax, investment, or other professional advice.',
  footerLinksCDNUrl: 'https://universalportal-sso.qacitizensbank.com/content/config/footerLinks.json'

};
