(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("rxjs"), require("@citizens/mfe-shared-state"));
	else if(typeof define === 'function' && define.amd)
		define(["rxjs", "@citizens/mfe-shared-state"], factory);
	else if(typeof exports === 'object')
		exports["comm-sso-footer-mfe"] = factory(require("rxjs"), require("@citizens/mfe-shared-state"));
	else
		root["comm-sso-footer-mfe"] = factory(root["rxjs"], root["@citizens/mfe-shared-state"]);
})(self, (__WEBPACK_EXTERNAL_MODULE__3961__, __WEBPACK_EXTERNAL_MODULE__9322__) => {
return 