import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ApiCallServiceService {

  constructor(private https: HttpClient) { }

  getFooterLinksFromCDN() {
    const url = environment.footerLinksCDNUrl;
    return this.https.get<any>(url);
  }
}
