import { ChangeDetectorRef, Component, Input, NgZone, OnInit } from '@angular/core';
import { sharedState } from '@citizens/mfe-shared-state';
import { ApiCallServiceService } from '../../services/api-call-service.service';
import { assetURL, environment } from '../../../environments/environment';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Location } from '@angular/common';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
  standalone: false
})
export class FooterComponent implements OnInit {
  public footer: any = environment.footer;
  public copyright: any = environment.footerCopyright;
  public disclaimer: any = environment.footerDisclaimer;

  public year = new Date().getFullYear();
  @Input() isDashboard = false;
  public butlerApp = false;
  public tokenPage = false;
  public bgColor = '#dad9d7';
  public textAlign: string;
  public isSMBUser = false;
  public footerCompanyLinks: any;
  public footerResourcesLinks: any;
  public footerBottomLinks: any;
  footerBottomFirstLinks: any;
  footerBottomSecondLinks: any;
  isLoginRoute: boolean = false;
  isCreds: string;
  universalLoginApp = '';

  get assetURL() {
    return assetURL;
  }

  constructor(
    private universalApiCallsService: ApiCallServiceService,
    private location: Location,
    private readonly cdr: ChangeDetectorRef ) {
   }

  ngOnInit() {
    this.getLoginRouteAndApp(this.location.path());
    this.location.onUrlChange((url: string) => {
      this.getLoginRouteAndApp(url);
      this.cdr.detectChanges();
    });

    const isTPO = this.universalLoginApp.toLowerCase() === 'tpoconnect';

    sharedState.getApplicationDataSub('footerCurrentSection').subscribe((section: any) => {
      this.isCreds = section;
      this.cdr.detectChanges();
    });
    this.checkForSmbUser()
    this.universalApiCallsService.getFooterLinksFromCDN().subscribe((res: any) => {
      this.footerCompanyLinks = res.footerCompanyLinks;
      this.footerResourcesLinks = res.footerResourcesLinks;
      this.footerBottomLinks = isTPO ? res.footerBottomLinks.slice(0, -1) :  res.footerBottomLinks;
      this.footerBottomFirstLinks = res.footerBottomLinks.slice(0,3);
      this.footerBottomSecondLinks = isTPO ? res.footerBottomLinks.slice(2, -1) : res.footerBottomLinks.slice(3)
    })

    if (sessionStorage.getItem('butler') == 'true') {
      this.butlerApp = true;
      this.bgColor = '#FFFFFF';
      this.textAlign = 'center';
    } else {
      this.butlerApp = false;
      this.bgColor = '#dad9d7';
    }
  }

  getLoginRouteAndApp(url: string) {
    if (!url) return;
    if (url.includes('sso/token')) {
      this.tokenPage = true;
      return;
    }
    this.tokenPage = false;
    this.isLoginRoute = url.includes('login');
    const queryString = url.split('?')[1];
    const params = new URLSearchParams(queryString);
    this.universalLoginApp = params.get('app') || '';
  }

  selectFooterLink(url: string): void {
    window.open(url, '_blank')
  }

  checkForSmbUser() {
    if (sessionStorage.getItem('isSMBUser') == 'true') {
      this.isSMBUser = true;
    } else {
      this.isSMBUser = false;
    }
  }
}